import classes from './AboutMe.module.css'
import { useTranslation } from 'react-i18next';

import profileImage from '../Assets/profile.jpg';

const AboutMe = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.aboutme}>
            <div>
                <h1 className={classes.title}>{t('About Me')}</h1>
                <p>{t("Hi!, I'm Francisco, a full-stack software developer with a background in law. I'm on a journey to transform my new found passion for software development into a fulfilling carrer.")}</p>
                <p>{t("Currently, I'm located in the San Francisco Bay Area. When I'm not writing code I enjoy practicing sports, reading or spending time outside.")}</p>
            </div>
            {/* <img src="https://corky.net/dotan/roleplaying/game/lib/exe/fetch.php?cache=&media=unavailable.jpg" alt="profile unavailable" height="450px"/> */}
            <img src={profileImage} alt="profile unavailable" height="450px"/>
        </section>
    )
}

export default AboutMe;