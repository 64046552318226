// import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import classes from './Header.module.css';
import { useTranslation } from 'react-i18next';
// import { t } from 'react-i18next';

const Header = (props) => {
    const { t } = useTranslation();
    // const [language, setLanguage] = useState('Español')
    // console.log("Language: " + language);
    
    

    const languageHandler = () => {
        // console.log('Button Clicked');
        if (props.language === 'Español') {
            // setLanguage('English')
            props.languageSetting('English');
        } else {
            // setLanguage('Español')
            props.languageSetting('Español');
        }
        console.log("Header Language: " + props.language);
        // console.log("Language: " + language);
        
    }
    

    // const userLang = props.languageSetting(language);
    
    // console.log('User Language: ' + userLang);

    return(
        <header className={classes.header}>
            <div>
                <h1>Francisco Rovirosa</h1>
                <h3>Software Developer</h3>
            </div>
            <nav>
                <ul>
                    <li><NavLink activeClassName={classes.active} to='/aboutme'>{t('About Me')}</NavLink></li>
                    <li><NavLink activeClassName={classes.active} to='/projects'>{t('Projects')}</NavLink></li>
                    <li><NavLink activeClassName={classes.active} to='/contactme'>{t('Contact Me')}</NavLink></li>
                    {/* <button onClick={languageHandler}>{language}</button> */}
                    <button type="button" className="btn btn-outline-light" onClick={languageHandler}>{props.language}</button>
                </ul>
            </nav>
        </header>)
};

export default Header;