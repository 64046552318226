import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import classes from './ProjectDetails.module.css';

const ProjectDetail = () => {
    const [selectedProject, setSelectedProject] = useState({});
    const projectId = useParams().id;

    useEffect(() => {
        fetch(`https://react-http-e45a9-default-rtdb.firebaseio.com/projects/${projectId-1}.json`)
        .then(response => {
            return response.json();
        })
        .then((data) => {
            setSelectedProject(data);
            console.log(data)
        });
}, [projectId])

    return (
            <section className={classes.projectdetails}>
                {/* <h1 className={classes.title}>Project Detail Page</h1> */}
                <h1 className={classes.title}>{selectedProject.name}</h1>
                <h3>{selectedProject.description}</h3>
                {/* <p>{projectId}</p> */}
                <button className='btn btn-light'><Link to='/projects'>Go Back to Projects</Link></button>
            </section>
        )

}

export default ProjectDetail;
