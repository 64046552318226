import { Route, Switch, Redirect } from 'react-router-dom';
import { Fragment, useState } from 'react';
import i18n from './Lang/i18n';

// import Modal from './Components/Modal';
// import Backdrop from './Components/Backdrop';
import Header from './Components/Header';
import AboutMe from './Pages/AboutMe';
import AllProjects from './Pages/AllProjects';
import ProjectDetails from './Pages/ProjectDetails';
import ContactMe from './Pages/ContactMe';
import Footer from './Components/Footer';
import './App.css';

function App() {
  // const [isModalOpen, setIsModalOpen] = useState(true);
  const [language, setLanguage] = useState('English');

  const setLang = (lang) => {
    setLanguage(lang);
    console.log("Current Language: " + language);
  }

  // const closeModal = () => {
  //   setIsModalOpen(false);
  // };

  if (language === 'English') {
    i18n.changeLanguage('en');
  }
  if (language === 'Español') {
    i18n.changeLanguage('es');
  }

  return (
    <Fragment>
      {/* {isModalOpen && <Modal show={isModalOpen} closeModal={closeModal} />} */}
      {/* <Modal show={this.state.modalIsOpen} closed={this.closeModal} /> */}
      <header>
        <Header languageSetting={setLang} language={language}/>
      </header>
      <main>
        <Switch>
          <Route path='/' exact>
            <Redirect to='/aboutme'/>
          </Route>
          <Route path='/aboutme'>
            <AboutMe />
          </Route>
          <Route path='/projects' exact>
            <AllProjects />
          </Route>
          <Route path='/projects/:id'>
            <ProjectDetails />
          </Route>
          <Route>
            <ContactMe path='contactme' />
          </Route>
        </Switch>
      </main>
      <Footer />  
    </Fragment>
  );
}

export default App;
