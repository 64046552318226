import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import classes from './AllProjects.module.css';
import { useTranslation } from 'react-i18next';

const AllProjects = () => {
    const [projects, setProjects] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
            fetch('https://react-http-e45a9-default-rtdb.firebaseio.com/projects.json')
            .then(response => {
                return response.json();
            })
            .then((data) => {
                setProjects(data);
            });
    }, [projects])

    return (
        <section className={classes.projects}>
            <h1 className={classes.title}>{t('Projects')}</h1>
            <p>{t("Find more about the projects I've been working on lately.")}</p>
            <table className="table table-light table-striped table-bordered table-hover">
                <thead className="table-dark" >
                    <tr>
                        <th>{t('Name')}</th>
                        <th>{t('Framework used')}</th>
                        {/* <th>Something else</th> */}
                    </tr>
                </thead>
                <tbody>
                    {
                        projects.map((project, id)=>{
                            return (
                                <tr key={project.id}>
                                    <td><Link to={`/projects/${project.id}`}>{project.name}</Link></td>
                                    <td>{project.framework}</td>
                                    {/* <td>something else</td> */}
                                </tr>
                                )
                        })
                    }
                </tbody>    
            </table>
            {/* <span>Click on the project's name to find more information related to the selected project</span> */}
        </section>
    )
}

export default AllProjects;