import { Link } from 'react-router-dom';

import Card from '../Components/Card';
import { useTranslation } from 'react-i18next';
import classes from './ContactMe.module.css';

const ContactMe = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.contactme}>
                <h1 className={classes.title}>{t('Contact Me')}</h1>
                <p>{t("If you have any questions, comments or feedback about my work, I've love to hear them.")}</p>
                {/* <p> ARREGLAR I also like listening about other people jumping into programming, as well as share my experiences transitioning into programming web development. XXXX </p> */}
                <p>{t("Feel free to contact me at any of the links provided below.")}</p>
                <ul className={classes.flex}>
                    <Card>
                        <li><Link to={{pathname: 'https://www.linkedin.com/in/franciscorovirosa/' }} target="_blank" ><img src='https://www.edigitalagency.com.au/wp-content/uploads/Linkedin-logo-icon-png.png' alt='Linkedin Logo'/>Linkedin</Link></li>
                    </Card>
                    <Card>
                        <li><Link to={{pathname: 'https://github.com/FranRovi' }} target="_blank" ><img src='https://image.flaticon.com/icons/png/512/25/25231.png' alt='GitHub Logo' />GitHub</Link></li>
                    </Card>
                    <Card>
                        <li><img src='https://www.clipartmax.com/png/middle/80-800539_envelope-png-youtube-logo-black-and-white-png.png' alt='Email icon'/><a href="mailto:rovirosafrancisco@gmail.com?subject= Hello Francisco" style={{color:"#1B2A49"}}>{t('Email')}</a></li>
                    </Card>
                </ul>
        </section>
    )
}

export default ContactMe;